import React from "react"
import { graphql } from "gatsby"
import EmbedContainer from "react-oembed-container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import FixedOrderBtn from "../components/FixedOrderBtn"
import Navbar from "../components/Navbar"

const DefaultPost = data => {
    const { content, title } = { ...data.data.wordpressWpFood }

    return (
        <Layout>
            <SEO
                title={`Yujo - ${title}`}
                description={
                    data.data.wordpressAcfOptions.options.site_description
                }
                image={
                    data.data.wordpressAcfOptions.options.seo_image.source_url
                }
            />
            <Navbar pageID={data.data.wordpressWpFood.wordpress_id} />
            <FixedOrderBtn data={data.data.wordpressAcfOptions.options} />
            <Hero data={data} pageClassName="food-post" />
            <div className="site-width entry-content">
                <div className="overlay--ink"></div>
                <div className="overlay"></div>
                <EmbedContainer markup={content}>
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </EmbedContainer>
            </div>
        </Layout>
    )
}

export default DefaultPost

export const pageQuery = graphql`
    query($id: String) {
        wordpressWpFood(id: { eq: $id }) {
            content
            wordpress_id
            title
            featured_media {
                source_url
                title
                localFile {
                    childImageSharp {
                        fixed(width: 500) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
        wordpressAcfOptions {
            options {
                toast
                deliveryService {
                    serviceName
                    serviceUrl
                }
                site_description
                seo_image {
                    source_url
                }
            }
        }
    }
`
